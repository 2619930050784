.message-box {
  display: flex;
  margin-bottom: 8px;
  max-width: 100%;

  &.user {
    justify-content: flex-end;

    .message-inner-box {
      background-color: #11509940;
    }
  }

  &.assistant {
    justify-content: flex-start;

    .message-inner-box {
      background-color: transparent;
    }
  }

  &.document {
    justify-content: flex-end;
    max-width: none;

    .message-inner-box {
      background-color: grey;
    }
  }

  &.loading_assistant {
    justify-content: flex-start;

    .message-inner-box {
      background-color: transparent;
    }
  }

  &.loading_user {
    justify-content: flex-end;

    .message-inner-box {
      background-color: transparent;
    }
  }
}

.message-inner-box {
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  max-width: 90%;
}
